window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');

require('./scripts/cookie');
require('./scripts/event');
require('./scripts/fadethis');

import header from './scripts/header';
import merge from './scripts/merge';
import navigation from './scripts/navigation';
import setBackgroundImage from './scripts/setBackgroundImage';
import toggle from './scripts/toggle';
import googleReCaptcha from './scripts/googleRecaptcha';
import 'simplebar';

import basket from './scripts/basket';

require('./vendor/basket');
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {

    /**
     * As soon as this file is loaded, jQuery will remove the `no-jquery`
     * class from the `body`. This indicates that jQuery has been loaded.
     */
    $('body').removeClass('no-jquery');

    /**
     * As soon as this file is loaded, jQuery will remove the `u-jquery`
     * class from the hidden `divs`. This indicates that jQuery has been loaded.
     */
    $('.u-jquery').removeClass('u-jquery');


    $(window).fadeThis({
        speed: 500,
        offset: -350,
        reverse: false,
        distance: 300
    });

    /**
     * Initialize Google reCaptcha
     */
    googleReCaptcha();

    /**
     * Object with breakpoint sizes defined. These are
     * based on Bootstrap's Grid.
     *
     * @type Object
     */
    var breakpoints = {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    };

    // Header
    header(breakpoints);

    $(window).resize(function () {
        header(breakpoints);
    });

    // Navigation
    navigation(breakpoints);

    /**
     * Make sure touch-enabled devices can view the drop-down menus
     * starting from a landscape mode (> breakpoints.sm)
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }
    });

    // Merge
    merge(breakpoints);

    $(window).resize(function () {
        merge(breakpoints);
    });

    // Toggle
    toggle(breakpoints);

    $(window).resize(function () {
        toggle(breakpoints);
    });

    // Replace `img` with `background-image` in parent `div`
    setBackgroundImage();

    // Webshop
    basket();

    filters({
        afterLoad: function () {
            // MatchHeight e.d.
        }
    });

    // MatchHeight e.d.
    $('.links__col .match').matchHeight({
        byRow: true
    });
    $('.team .text').matchHeight({
        byRow: true
    });
    $('.footer .cols h2').matchHeight({
        byRow: true
    });
    $('.footer .match').matchHeight({
        byRow: true
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    // Slick
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            // 'share',
            //'download',
            //'zoom',
            'close'
        ]
    });

    // Slider Hero
    $('.js-hero-slider section').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        rows: 0,
        dots: true,
        autoplaySpeed: 4000
    });
    // Reviews slider
    $('.js-reviews section').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        rows: 0,
        dots: false,
        autoplaySpeed: 8000,
        adaptiveHeight: true
    });

    // Smooth scroll
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    });

    $('.team .aboutus .item:nth-child(1)').removeClass('smaller');
    $('.team .aboutus .item:nth-child(2)').removeClass('smaller');
    $('.team .aboutus .item:nth-child(2)').removeClass('smaller');

    $('.team .aboutus .item:nth-child(1)').addClass('bigger');
    $('.team .aboutus .item:nth-child(2)').addClass('bigger');


    // Close notification for 1 day
    if ($('.melding').length > 0) {
        $('.js-melding-close').click(function () {
            Cookies.set('notification', false);

            $('.melding').hide();
            $('body').removeClass('has-notification');
        });

        if (Cookies.get('notification') == 'false') {
            $('.melding').hide();
            $('body').removeClass('has-notification');
        } else {
            $('.melding').show();
            $('body').addClass('has-notification');
        }
    }

})(jQuery);
